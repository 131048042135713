/* Ensure the navbar remains transparent and on top of the background */
html,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: url("./assests/images/Pattern\ Top.png") no-repeat;
  background-position: center top;
  background-size: 100%;
}
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.home-page-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.home-page-bg-white {
  background-color: #ffffffbf;
}

.page-heading {
  padding: 60px 0px;
}
/* Transparent Navbar */
.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.navbar.sticky-top {
  background-color: #ffffff;
}
/* Center the navbar content and set its width to 70% */
.navbar-container {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo styling */
.navbar-logo h1 {
  color: #007bff;
  /* Logo color (blue) */
  font-size: 28px;
  margin: 0;
}

/* Navbar links styling */
.navbar-links {
  list-style-type: none;
  display: flex;
  gap: 7px;
}

.navbar-links li {
  display: inline;
  margin: auto;
  color: #007bff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0.5rem;
}

.navbar-links a {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0.5rem;
  position: relative;
}

.navbar-links a::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #007bff;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navbar-links a:hover::after {
  transform: scaleX(1);
  /* Hover underline effect */
}


.dropMenu {
  width: max-content;
  border: none;
}

.dropMenu li {
  display: block;
  padding: 5px 0px;
}

/* Register button styling */
.register-btn {
  color: #000000;
  padding: 10px !important;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.register-btn:hover {
  background-color: #e6b800;
}

.home-hero-section h1 {
  font-size: 94px;
  color: #000;
}

h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}

.highlight-text {
  color: #1a73e8;
}

.subtext {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 40px;
}

.bold-text {
  font-weight: 700;
  color: #1a73e8;
}

.cta-button {
  background: radial-gradient(
    108.44% 108.44% at 59.69% 50%,
    #ffe79f 0%,
    #f4ba0d 60.48%,
    #ca9e1c 100%
  );
  color: #000000;
  font-size: 1.2rem;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.right-section img {
  width: 100%;
  max-width: 400px;
}

.container-wrapper {
  display: flex;
  justify-content: center;
  padding: 2rem;
  align-content: center;
}

.left-section {
  width: 50%;
}

.right-section {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-section img {
  max-width: 100%;
  height: auto;
}

.frame2 {
  padding: 3rem 2rem;
  text-align: center;
}
.section-background {
  position: relative;
}

.section-background .vector-img {
  position: absolute;
  right: 0;
  z-index: -1;
}
.section-background .process-img {
  position: absolute;
  z-index: -1;
}
.section-background .ellipse-img {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.services-header {
  font-size: 2.5rem;
  font-weight: 700;
  padding-top: 2rem;
  color: #333;
}

.services-subheader {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  color: #777;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two columns per row */
  grid-gap: 2rem;
  margin-top: 2rem;
}

.service-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.service-card p {
  font-size: 1rem;
  color: #555;
}

/* Footer */
.footer-bg {
  background: #f0f6fd;
}

.footer-section {
  padding: 60px 0px;
}

.footer-divider {
  border-bottom: 1px solid #0000001f;
}

.footer-touch h4 {
  font-weight: 500;
}

.footer-touch .icon {
  padding-bottom: 20px;
}

.footer-touch .icon ul {
  padding: 0;
  margin: 0;
  max-width: 263px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-touch .icon ul li {
  list-style: none;
  padding: 12px;
  border-radius: 5px;
  /* background-color: #1877f214; */
}

.footer-touch .icon ul li:nth-child(2) {
  background: #0e76a8;
}

.footer-touch .icon ul li:nth-child(2) a {
  color: #ffffff;
}

/* .footer-touch .icon ul li:nth-child(3) {
  background: #1da1f214;
} */

.footer-touch .icon ul li:nth-child(3) {
  background: linear-gradient(
    45deg,
    rgba(255, 214, 0, 0.08) 8.39%,
    rgba(255, 1, 0, 0.08) 50%,
    rgba(216, 0, 185, 0.08) 91.61%
  );
}

.footer-touch .icon ul li a {
  text-decoration: none;
  text-transform: capitalize;
  color: #000000;
}

.footer-touch .icon ul li a i {
  border-right: 1px solid #061c3d29;
  padding: 6px;
  margin-right: 6px;
}

.footer-touch .contact p {
  margin: 0;
  color: #040505cc;
  text-decoration: underline;
}

.contact-form {
  max-width: 360px;
  margin-left: auto;
}

.contact-form .input-field {
  background-color: #ffffff;
  padding: 14px 24px 14px 24px;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #0405053d;
  margin-bottom: 10px;
}

.contact-form .input-field input,
.contact-form .input-field input:focus-visible,
.contact-form .input-field input:active {
  border: none;
  outline: none;
}

.contact-form .input-field i {
  margin-right: 10px;
}

.contact-form .button-wrapper {
  text-align: end;
}

.contact-form .button-wrapper button {
  padding: 10px 32px;
  border-radius: 7px;
  color: #1b5faa;
  font-weight: 600;
  border: 1.5px solid #1b5faa;
  background-color: transparent;
}

.quick-links ul,
.terms ul {
  margin: 0;
  padding: 0;
}

.quick-links ul li {
  list-style: none;
  padding-bottom: 15px;
  text-transform: capitalize;
}

.quick-links ul li:first-child a {
  font-weight: 600;
}

.quick-links ul li a,
.terms ul li a {
  text-decoration: none;
  color: #040505;
}

.terms {
  text-align: end;
}

.terms ul li {
  display: inline-block;
  padding-right: 40px;
}

/* Service Page */

.card-wrapper {
  padding: 24px 32px;
  border: 1px solid #d3ebf9;
  background: linear-gradient(357.79deg, #f4fafe 8.29%, #ffffff 97.22%);
  border-radius: 20px;
  height: 100%;
}

.white-background {
  background: #ffffff;
}

.card-wrapper .icon,
.card-wrapper .heading,
.card-wrapper .content {
  margin-bottom: 20px;
}

.card-wrapper .icon img {
  width: 68px;
}

.card-wrapper .heading h4 {
  font-size: 28px;
  font-weight: 600;
}

.card-wrapper .content p {
  font-size: 18px;
  color: #42526b;
}

.card-wrapper .tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.card-wrapper .tags span {
  background: #8080801a;
  border-radius: 10px;
  padding: 5px 10px;
  color: #161a1f;
  font-weight: 400;
  font-size: 18px;
}

.section-wrapper {
  padding: 80px 0;
}

.yellow-bg {
  background-color: #fffcf3;
}

.expertise-section ul {
  margin: 0;
  padding: 0;
}

.expertise-section ul li {
  padding: 13px 12px;
  border-radius: 20px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  list-style: none;
  color: #282828;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 16px;
}

.expertise-section ul li img {
  margin-right: 16px;
}

.testimonial-header h1 {
  font-size: 57px;
  margin-bottom: 26px;
}

.carousel-button {
  padding: 16px;
  margin-right: 10px;
  border-radius: 7px;
  background: #f0f5ff;
  border: none;
}

.carousel-slide {
  border: 10px solid #fff3cf;
  border-radius: 16px;
  padding: 48px;
  position: relative;
}

.carousel-slide .icons svg {
  fill: #ff8800;
  font-size: 37px;
  margin-right: 8px;
}

.carousel-slide .icons,
.carousel-slide .content {
  margin-bottom: 30px;
}

.carousel-slide .content {
  font-size: 18px;
  color: #42526b;
  font-weight: 400;
}

.carousel-slide .user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.carousel-slide .user img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}

.carousel-slide .user .name p {
  margin-bottom: 4px;
  color: #061c3d;
  font-weight: 600;
  font-size: 16px;
}

.carousel-slide .user .name span {
  font-size: 14px;
  font-weight: 400;
}

.carousel-slide .logo {
  position: absolute;
  right: 48px;
  bottom: 8px;
}

/* Contact Page */
.contact-wrapper .input-field {
  margin-bottom: 20px;
}

.contact-wrapper .input-field label {
  margin-bottom: 5px;
}

.contact-wrapper .input-field label,
.contact-wrapper .input-field input,
.contact-wrapper .input-field textarea {
  width: 100%;
  display: block;
}

.contact-wrapper .input-field input,
.contact-wrapper .input-field input:active,
.contact-wrapper .input-field input:focus-visible,
.contact-wrapper .input-field textarea {
  outline: none;
  border: 1px solid #0405053d;
  padding: 11px 24px;
  border-radius: 20px;
}

.primary-button {
  background: radial-gradient(
    108.44% 108.44% at 59.69% 50%,
    #ffe79f 0%,
    #f4ba0d 60.48%,
    #ca9e1c 100%
  );
  border-radius: 7px;
  padding: 24px 40px;
  border: none;
  font-size: 20px;
  font-weight: 600;
}

.contact-info {
  background-image: url("./assests/images/contactInfo.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.sub-heading {
  color: #000000;
}

.heading {
  font-weight: 600;
  font-size: 40px;
}

.contact-info .sub-heading {
  margin-bottom: 25px;
}

.contact-info-card .heading {
  margin-bottom: 20px;
}

.contact-info-card .small-divider {
  width: 27px;
  height: 3px;
  background: #000000;
  margin-bottom: 30px;
}

.contact-info-card p.heading-item {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}

.contact-info-card p {
  font-weight: 400;
  font-size: 18px;
  color: #42526b;
}

.chooseUs {
  background: rgba(252, 249, 240, 1);
}

/* plans data */

.subscription-section {
  padding: 40px;
  max-width: 1200px;
  margin: auto;
}

.heading-row {
  text-align: center;
  margin-bottom: 30px;
}

.toggle-container {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.toggle-button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #666;
  font-weight: bold;
}

.toggle-button.active {
  background-color: #ffc107;
  color: #fff;
  border-radius: 20px;
}

.card-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  background-color: #f0f9ff;
  border: none;
}

.card:hover svg rect {
  fill: #1b5faa;
}
.card:hover svg path {
  stroke: #ffffff;
}
.card h3 {
  font-size: 24px;
  color: #004aad;
}

.seoplan-icon {
  width: 24px;
  height: 24px;
}

.card .price {
  font-size: 28px;
  color: #333;
  margin: 10px 0;
}

.card .price span {
  font-size: 16px;
  color: #888;
}

.card .description {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
}

.features {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.features li {
  font-size: 14px;
  color: #555;
  padding: 5px 0;
}

.features .disabled {
  color: #ccc;
  text-decoration: line-through;
}

.buy-button {
  padding: 10px;
  width: 170px;
  border-radius: 7px;
  color: #1b5faa;
  font-weight: 600;
  border: 1.5px solid #1b5faa;
  background-color: transparent;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.buy-button:hover {
  background-color: #003078;
}
/* News Page */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-load-more {
  border-radius: 7px;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  cursor: pointer;
  line-height: 24px;
  font-weight: 600px;
  margin-bottom: 50px;
  padding: 10px 30px 10px 30px;
  border: 1px solid #8c8c8c;
}

.line-horizontal {
  width: 108px;
  background: #121416;
  height: 1px;
  cursor: pointer;
}

.article-sub-heading {
  color: #ffffff;
  top: 400px;
  margin-left: 30px;
  font-weight: 400px;
  font-size: 40px;
  width: 79.33%;
}

.article-sub-heading1 {
  color: #ffffff;
  top: 500px;
  margin-left: 30px;
  font-weight: 400px;
  font-size: 26px;
  width: 79.33%;
}

.article-sub-heading2 {
  color: #ffffff;
  top: 550px;
  margin-left: 30px;
  font-weight: 400px;
  width: 79.33%;
  display: flex;
  gap: 20px;
}

.sub-headig-image {
  border-radius: 4px;
  padding: 5px 20px;
  gap: 10px;
  color: #ffffff;
  backdrop-filter: blur(12px);
  font-size: 24px;
  position: absolute;
  top: 25%;
}

.sub-main-heading {
  font-size: 48px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Clash Display Variable";
  position: absolute;
  top: 38%;
  transform: translate(-50%, -50%);
  line-height: 1.2;
  left: 50%;
}

.sub-heading-3 {
  font-size: 1.25rem;
  color: #e5e5e5;
  font-weight: 400;
  font-family: "General Sans Variable";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  line-height: 1.5;
  text-align: center;
}

.read-now {
  border-radius: 7px;
  padding: 16px 24px;
  background: #ffffff;
  color: #191301;
  font-weight: 600;
  font-size: 1rem;
}

.vector_12-background {
  position: relative;
}

.vector_12-background .vector_12_img {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

/* Responsive adjustments for smaller devices */
@media (min-width: 992px) {
  .navbarItem-hover:hover .dropMenu {
    display: block;
  }
}
@media (max-width: 768px) {
  .sub-main-heading {
    font-size: 2rem;
  }

  .sub-heading-3 {
    font-size: 1rem;
  }

  .read-now {
    padding: 12px 20px;
    font-size: 0.875rem;
  }
  .home-hero-section {
    text-align: center;
    margin-bottom: 40px;
  }
  .home-hero-section h1 {
    font-size: 40px;
  }
}

@media (min-width: 880px) {
  article-sub-heading {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .sub-main-heading {
    font-size: 1.5rem;
  }

  .sub-heading-3 {
    font-size: 0.875rem;
  }

  .read-now {
    padding: 10px 16px;
    font-size: 0.75rem;
  }

  .p-article {
    font-family: "General Sans Variable";
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    color: #121416;
  }

  .p-heading {
    font-family: "General Sans Variable";
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    color: #121416;
    letter-spacing: 0.5px;
  }
}

.port-heading {
  font-family: Clash Display Variable;
  font-size: 60px;
  font-weight: 500;
  line-height: 68px;
  letter-spacing: -0.025em;
  text-align: left;
  color: #121a22;
}

.sub-text {
  font-family: General Sans Variable;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.01em;
  text-align: left;
}

.right-paragraph {
  font-family: General Sans Variable;
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
  width: 40%;
}

.button_previous {
  width: 84px;
  height: 84px;
  top: 673px;
  left: 202px;
  gap: 0px;
  border-radius: 280px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: #ffffff;
  border: 1px solid #d3ebf9;
}
.seo-button {
  font-family: General Sans Variable;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.012em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 5px 30px 5px 30px;
  gap: 0px;
  border-radius: 170px;
  opacity: 0px;
  background: #ffc759;
  outline: none;
  font-weight: bold;
}

.back-cont {
  padding: 12px 0px 0px 0px;
  border-radius: 1000px;
  opacity: 0px;
  background: #fff9ee;
}
.process-text {
  width: 260px;
}
.process-sub {
  font-family: General Sans Variable;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.icon-heading {
  width: 90px;
  height: 30px;
  left: 85px;
  gap: 0px;
  border-radius: 20px;
  opacity: 0px;
  text-align: center;
  border: 2px solid #997408;
}

.hero-section {
  position: relative;
  height: 600px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.content-wrapper {
  position: relative;
  z-index: 2;
  height: 100%;
}

.read-now-btn {
  background-color: white;
  color: black;
  border: none;
  padding: 12px 32px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.read-now-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.travel-tag {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 4px 16px;
  border-radius: 4px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .hero-section {
    height: 450px;
  }

  .main-title {
    font-size: 1.75rem !important;
  }

  .subtitle {
    font-size: 0.9rem !important;
    padding: 0 20px;
  }
}

@media (max-width: 576px) {
  .hero-section {
    height: 350px;
  }

  .main-title {
    font-size: 1.5rem !important;
  }
}
.slider {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}

.slider img {
  width: 80%;
  border-radius: 10px;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}

.tick-para {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  padding: 5px 0px;
}

.tick-para img {
  padding-top: 7px;
}

.heading-bottom {
  font-family: General Sans Variable;
  font-size: 40px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.news-heading {
  font-family: General Sans Variable;
  font-size: 36px;
  font-weight: 400;
  line-height: 48.6px;
  letter-spacing: 0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.left-no {
  font-family: General Sans Variable;
  font-size: 28px;
  font-weight: 400;
  line-height: 37.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.carousel-container {
  position: relative;
  gap: 20px;
}

.slider-image {
  width: 100%;
  border-radius: 20px;
  gap: 20px;
}

.custom-dot-list-style {
  margin-top: 20px;
}
.hero-container {
  width: 100%;
  overflow: hidden;
}

.hero-section {
  height: 600px;
  width: 100%;
  color: white;
  overflow: hidden;
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("./assests/images/mainImage.svg"); */
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.tags-container {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.tag {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  border-radius: 4px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.25rem;
  opacity: 0.9;
}

.hero-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  font-size: 1rem;
  opacity: 0.8;
}

.hero-meta span {
  position: relative;
}

.hero-meta span:not(:last-child)::after {
  content: "•";
  position: absolute;
  right: -1rem;
}

.price-card {
  padding: 36px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
}
.price-card.silver {
  background: radial-gradient(
    81.03% 86.75% at 92.01% 8.34%,
    #f0f6fd 3.94%,
    #ffffff 52.09%,
    #ffffff 71.2%
  );
}
.price-card.diamond {
  background: radial-gradient(
    81.03% 86.75% at 92.01% 8.34%,
    #d5f190 3.94%,
    #ffffff 52.09%,
    #ffffff 71.2%
  );
}

.price-card.gold {
  background: radial-gradient(
    81.03% 86.75% at 92.01% 8.34%,
    #ffe79f 3.94%,
    #ffffff 52.09%,
    #ffffff 71.2%
  );
}

.price-card.premium {
  background: radial-gradient(
    81.03% 86.75% at 92.01% 8.34%,
    #90cef1 3.94%,
    #ffffff 52.09%,
    #ffffff 71.2%
  );
}
.price-card.platinum {
  background: radial-gradient(
    81.03% 86.75% at 92.01% 8.34%,
    #90cef1 3.94%,
    #ffffff 52.09%,
    #ffffff 71.2%
  );
}

.price-card .head h2 {
  text-transform: capitalize;
  font-weight: 600;
}

.price-card .head p {
  text-transform: capitalize;
  font-size: 21px;
}

.price-card .price {
  display: flex;
  align-items: center;
}

.price-card .price h4 {
  font-size: 44px;
  font-weight: 600;
}

.price-card .cta button {
  font-weight: 500;
  padding: 9px 12px !important;
  font-size: 19px;
}

.price-card .cta .silver {
  color: #1b5faa;
  font-size: 16px;
  font-weight: 600;
  border: none;
  background: none;
}

.price-wrapper .list {
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 15px;
}

.price-wrapper .list ul li {
  margin-bottom: 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.price-wrapper .list ul li img {
  width: 20px;
  margin-right: 12px;
}

.price-wrapper .list ul li p {
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
}

.price-header p {
  font-size: 24px;
  font-weight: 500;
  color: #484848;
}

.price-header p span {
  padding: 8px 10px 8px 10px;
  border-radius: 4px;
  background: #fffbef;
  color: #ca9e1c;
  transform: rotate(-3.7deg);
  display: inline-block;
  margin-left: 6px;
}
.price-for-desktop {
  display: block;
}
.price-for-mobile {
  display: none;
}

.price-tabs {
  max-width: 786px;
  overflow-x: scroll;
  margin-bottom: 20px;
  display: grid;
  place-items: center;
}
.price-tabs ul {
  border: none;
  align-items: center;
  width: max-content;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 10px;
  background: #fff9ee;
  border-radius: 50px;
}

.price-tabs ul li {
  width: 100%;
}

.price-tabs ul li button {
  border-radius: 50px !important;
  border: none !important;
  padding: 8px 30px;
  background: transparent !important;
  text-transform: capitalize;
  transition: 0.5s all linear;
}
.price-tabs ul li button.active {
  border: none !important;
  border-radius: 50px !important;
  padding: 8px 30px !important;
  background-color: #ffc759 !important;
}

.custom-gradiant {
  border: 100px solid;
  border-image-source: linear-gradient(
    296.17deg,
    #ffc700 31.52%,
    rgba(250, 250, 250, 0.64) 55.94%
  );
  gap: 0px;
  /* border: 850px 0px 0px 0px; */
  opacity: 0px;
}

.nav-pills .nav-link {
  font-weight: bold;
  border-radius: 50px;
  padding: 0.5rem 1.5rem;
}
.nav-pills .nav-link.active {
  background-color: #ffc107;
  color: #000;
}
.bg-warning,
.bg-info {
  transition: all 0.3s ease;
}
.tabnav {
  display: "inline-block";
  border: "2px solid #E5E9F2";
  border-radius: "50px";
  padding: "4px";
  background: #fff9ee;
}

.react-multi-carousel-item {
  transition: all 0.3s ease;
}

.react-multi-carousel-item--active {
  transform: scale(1);
}

/* Optional: Add custom styling for the button group */
.custom-button-group {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
}
.arrowButton {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 1px solid #d3ebf9;
  border-radius: 50%;
  background-color: #ffffff;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.arrowButton svg {
  color: #000000;
  font-size: 30px;
}

.portfolio-slider
  .react-multi-carousel-list
  .react-multi-carousel-track
  .react-multi-carousel-item {
  padding: 0px 7px;
}

/* 404 Not Found Page */
.notFoundContainer {
  background-color: #007aff;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notFoundContainer p {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

.notFoundContainer h1 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  text-shadow: #0062cc 1px 1px, #0062cc 2px 2px, #0062cc 3px 3px,
    #0062cd 4px 4px, #0062cd 5px 5px, #0062cd 6px 6px, #0062cd 7px 7px,
    #0062ce 8px 8px, #0063ce 9px 9px, #0063ce 10px 10px, #0063ce 11px 11px,
    #0063cf 12px 12px, #0063cf 13px 13px, #0063cf 14px 14px, #0063cf 15px 15px,
    #0063d0 16px 16px, #0064d0 17px 17px, #0064d0 18px 18px, #0064d0 19px 19px,
    #0064d1 20px 20px, #0064d1 21px 21px, #0064d1 22px 22px, #0064d1 23px 23px,
    #0064d2 24px 24px, #0065d2 25px 25px, #0065d2 26px 26px, #0065d2 27px 27px,
    #0065d3 28px 28px, #0065d3 29px 29px, #0065d3 30px 30px, #0065d3 31px 31px,
    #0065d4 32px 32px, #0065d4 33px 33px, #0066d4 34px 34px, #0066d4 35px 35px,
    #0066d5 36px 36px, #0066d5 37px 37px, #0066d5 38px 38px, #0066d5 39px 39px,
    #0066d6 40px 40px, #0066d6 41px 41px, #0067d6 42px 42px, #0067d6 43px 43px,
    #0067d7 44px 44px, #0067d7 45px 45px, #0067d7 46px 46px, #0067d7 47px 47px,
    #0067d8 48px 48px, #0067d8 49px 49px, #0068d8 50px 50px, #0068d9 51px 51px,
    #0068d9 52px 52px, #0068d9 53px 53px, #0068d9 54px 54px, #0068da 55px 55px,
    #0068da 56px 56px, #0068da 57px 57px, #0068da 58px 58px, #0069db 59px 59px,
    #0069db 60px 60px, #0069db 61px 61px, #0069db 62px 62px, #0069dc 63px 63px,
    #0069dc 64px 64px, #0069dc 65px 65px, #0069dc 66px 66px, #006add 67px 67px,
    #006add 68px 68px, #006add 69px 69px, #006add 70px 70px, #006ade 71px 71px,
    #006ade 72px 72px, #006ade 73px 73px, #006ade 74px 74px, #006bdf 75px 75px,
    #006bdf 76px 76px, #006bdf 77px 77px, #006bdf 78px 78px, #006be0 79px 79px,
    #006be0 80px 80px, #006be0 81px 81px, #006be0 82px 82px, #006be1 83px 83px,
    #006ce1 84px 84px, #006ce1 85px 85px, #006ce1 86px 86px, #006ce2 87px 87px,
    #006ce2 88px 88px, #006ce2 89px 89px, #006ce2 90px 90px, #006ce3 91px 91px,
    #006de3 92px 92px, #006de3 93px 93px, #006de3 94px 94px, #006de4 95px 95px,
    #006de4 96px 96px, #006de4 97px 97px, #006de4 98px 98px, #006de5 99px 99px,
    #006ee5 100px 100px, #006ee5 101px 101px, #006ee6 102px 102px,
    #006ee6 103px 103px, #006ee6 104px 104px, #006ee6 105px 105px,
    #006ee7 106px 106px, #006ee7 107px 107px, #006ee7 108px 108px,
    #006fe7 109px 109px, #006fe8 110px 110px, #006fe8 111px 111px,
    #006fe8 112px 112px, #006fe8 113px 113px, #006fe9 114px 114px,
    #006fe9 115px 115px, #006fe9 116px 116px, #0070e9 117px 117px,
    #0070ea 118px 118px, #0070ea 119px 119px, #0070ea 120px 120px,
    #0070ea 121px 121px, #0070eb 122px 122px, #0070eb 123px 123px,
    #0070eb 124px 124px, #0071eb 125px 125px, #0071ec 126px 126px,
    #0071ec 127px 127px, #0071ec 128px 128px, #0071ec 129px 129px,
    #0071ed 130px 130px, #0071ed 131px 131px, #0071ed 132px 132px,
    #0071ed 133px 133px, #0072ee 134px 134px, #0072ee 135px 135px,
    #0072ee 136px 136px, #0072ee 137px 137px, #0072ef 138px 138px,
    #0072ef 139px 139px, #0072ef 140px 140px, #0072ef 141px 141px,
    #0073f0 142px 142px, #0073f0 143px 143px, #0073f0 144px 144px,
    #0073f0 145px 145px, #0073f1 146px 146px, #0073f1 147px 147px,
    #0073f1 148px 148px, #0073f1 149px 149px, #0074f2 150px 150px,
    #0074f2 151px 151px, #0074f2 152px 152px, #0074f3 153px 153px,
    #0074f3 154px 154px, #0074f3 155px 155px, #0074f3 156px 156px,
    #0074f4 157px 157px, #0074f4 158px 158px, #0075f4 159px 159px,
    #0075f4 160px 160px, #0075f5 161px 161px, #0075f5 162px 162px,
    #0075f5 163px 163px, #0075f5 164px 164px, #0075f6 165px 165px,
    #0075f6 166px 166px, #0076f6 167px 167px, #0076f6 168px 168px,
    #0076f7 169px 169px, #0076f7 170px 170px, #0076f7 171px 171px,
    #0076f7 172px 172px, #0076f8 173px 173px, #0076f8 174px 174px,
    #0077f8 175px 175px, #0077f8 176px 176px, #0077f9 177px 177px,
    #0077f9 178px 178px, #0077f9 179px 179px, #0077f9 180px 180px,
    #0077fa 181px 181px, #0077fa 182px 182px, #0077fa 183px 183px,
    #0078fa 184px 184px, #0078fb 185px 185px, #0078fb 186px 186px,
    #0078fb 187px 187px, #0078fb 188px 188px, #0078fc 189px 189px,
    #0078fc 190px 190px, #0078fc 191px 191px, #0079fc 192px 192px,
    #0079fd 193px 193px, #0079fd 194px 194px, #0079fd 195px 195px,
    #0079fd 196px 196px, #0079fe 197px 197px, #0079fe 198px 198px,
    #0079fe 199px 199px, #007aff 200px 200px;
}

.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #fff;
  left: -100px;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
}

.ophanim-news-card img {
  width: 100%;
}
.ophanim-news-card p {
  cursor: pointer;
}
.ophanim-news-card a {
  color: #000;
  text-decoration: none;
}
.ophanim-news-card h5 {
  text-transform: capitalize;
}

.ophanim-news-card .truncate {
  display: -webkit-box; /* Use for multiline text truncation */
  -webkit-box-orient: vertical; /* Set orientation for text flow */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis (...) */
  -webkit-line-clamp: 3; /* Number of lines to show */
  line-height: 1.5; /* Line height for proper spacing */
  max-height: calc(1.5em * 3); /* Control max height based on lines */
}

.news-page-image{
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('./assests/images/newsPageImages.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    box-shadow: 120px 0 white, 110px 0 white, 100px 0 white,
      0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }
  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white,
      110px 0 white;
  }
  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      130px 0 white, 120px 0 white;
  }
  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 130px 0 white;
  }
  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}

@media (min-width: 992px) {
  .navbar.sticky-top {
    background-color: transparent;
  }

  .mainNavBarContainer.sticky-top .navbar-brand {
    display: none;
  }
  .mainNavBarContainer.sticky-top #navbarSupportedContent {
    justify-content: center !important;
  }
  .mainNavBarContainer.sticky-top #navbarSupportedContent ul {
    background-color: #ffffffe6;
    border-radius: 13px;
    gap: 0px;
  }
  .mainNavBarContainer.sticky-top
    #navbarSupportedContent
    ul
    li
    button.register-btn {
    font-size: 16px;
    padding: 2px 8px !important;
  }
  .mainNavBarContainer.sticky-top #navbarSupportedContent ul li {
    padding: 6px;
  }
}

/* Responsive Styles */
@media (max-width: 992px) {
  .navbar-links li {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 500px;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1.125rem;
  }

  .hero-content {
    padding: 1.5rem;
  }

  .tags-container {
    top: 1.5rem;
    left: 1.5rem;
  }
  .price-for-desktop {
    display: none;
  }
  .price-for-mobile {
    display: block;
  }
  .notFoundContainer h1 {
    font-size: 6em;
  }
}

@media (max-width: 576px) {
  .page-heading h1{
    font-size: 40px;
  }
  .hero-section {
    height: 400px;
  }

  .hero-title {
    font-size: 1.5rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .hero-meta {
    font-size: 0.875rem;
    gap: 1rem;
  }

  .hero-meta span:not(:last-child)::after {
    right: -0.75rem;
  }

  .hero-content {
    padding: 1rem;
  }

  .tags-container {
    top: 1rem;
    left: 1rem;
  }

  .tag {
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
  }

  .price-header h1 {
    font-size: 34px;
  }
}
